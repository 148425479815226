import cn from "clsx";

import "./statistics-cta.scss";

import { ReactNode } from "react";
import { Alignment, MediaAlignment } from "design-system/types/types";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  Statistic,
  StatisticProps,
} from "design-system/components/primitives/statistic/statistic";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";

export interface StatisticsCtaProps {
  align?: Alignment;
  media?: MediaAssetProps;
  title?: ReactNode;
  subtitle?: ReactNode;
  statistics?: Array<StatisticProps>;
  cta?: CtaLinkProps;
  imageAlignment?: MediaAlignment;
}

/**
 * ## See it in use on...
 * - The [Kitchen Sink alumni page](/story/snowflakes-alumni-example-pages-kitchen-sink--story)
 *
 * - **`data-region: cb__statistics-cta`**
 */
export function StatisticsCta({
  align = "full",
  media,
  title,
  subtitle,
  cta,
  statistics,
  imageAlignment = "left",
}: StatisticsCtaProps) {
  const count = statistics?.length || 0;
  const statisticsClass = cn(
    `hbs-statistics-cta__statistics hbs-statistics-cta--${count}`,
    media && "hbs-statistics-cta--has-media",
  );

  return (
    <div
      className={cn(
        `hbs-global-align-${align}`,
        "hbs-statistics-cta",
        "hbs-component--statistics-cta",
        `hbs-statistics-cta--image-${imageAlignment}`,
      )}
      data-region="cb__statistics-cta"
    >
      {media && (
        <div className="hbs-statistics-cta__media">
          <MediaAsset {...media} aspectRatio="3/2" />
        </div>
      )}

      <div className="hbs-statistics-cta__content">
        <h2 className="hbs-statistics-cta__title">{title}</h2>
        {subtitle ? (
          <p className="hbs-statistics-cta__subtitle">{subtitle}</p>
        ) : null}
        {cta ? (
          <CtaLink {...cta} isSmall className="hbs-statistics-cta__cta" />
        ) : null}
        {statistics ? (
          <div className={statisticsClass}>
            <div className="hbs-statistics-cta__list">
              {statistics.map((statistic, i) => (
                <Statistic {...statistic} key={i} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
